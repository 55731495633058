import * as React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container } from "react-bootstrap"



const IndexPage = () => (
  <Layout>
    <Seo title="About Us" />
    {/* <Container className="d-lg-flex heroWidth">
        <div lg={6} className="">
          <h1></h1>
          
        </div>
        <div className="">
          <StaticImage
            src="../images/child-white-shirt.jpg"
            loading="eager"
            width={350}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Child in white shirt talking into a microphone"
          />
        </div>
    </Container> */}
    <div className="masthead mb-5">
      <Container className="singleCol">
        <h1>Our Speech Therapists</h1>
      </Container>
    </div>
    <Container className="singleCol therapists">
    <p className="float-img float-none float-md-start me-md-3">
        <StaticImage 
          src="../images/kris-langley.jpg"
          alt="Kris Langley"
          placeholder="blurred"
          className="float-img text-center"
        />
      </p>
      <h2>Kris Langley <small>(she/her)</small></h2>
      <h3>M.A., M.S. CCC-SLP</h3>
      
      <p>
        Kris is the owner of Vibe Speech Therapy. Kris received her B.A. in International Relations from the University of California at Davis in 1992, her M.S. in Communicative Disorders from San Francisco State University in 2001, and an M.A. in Integral Counseling Psychology from the California Institute of Integral Studies in 2010. Additionally, she has comprehensive training in mindfulness and somatic based counseling; including the Hakomi Method, Neuro Affective Touch, and a meditation and mantra practice. 
      </p>
      <p>
        Kris has worked for the San Francisco Unified School District since 2001, as a clinical supervisor at San Francisco State University from 2011-2014, and continues to supervise graduate students and Clinical Fellows in both public school and private practice settings. She is a licensed provider of Fast ForWord, a computer based training program, working with Fast ForWord since 1997. Kris provides parent/client education and shares resources to help clients generalize their skills outside of therapy sessions. 
      </p>
      <p>
        Kris lives in Marin County, California. She has worked in public schools since 2001 and has been in private practice since 2015.
      </p>

      <hr />

      <p className="float-img float-none float-md-start me-md-3">
        <StaticImage 
          src="../images/daniel-pearlstone.png"
          alt="Daniel Pearlstone"
          placeholder="blurred"
          className="float-img text-center"
        />
      </p>
      <h2>Daniel Pearlstone <small>(he/him)</small></h2>
      <h3>M.S. CCC-SLP </h3>
      
      <p>
        Daniel graduated magna cum laude from San Francisco State University with a Masters Degree in Communicative Disorders and holds Certifications from ASHA in Speech Language Pathology and from SFSU in Alternative and Augmentative Communication and a specialization in treating Autism Spectrum Disorder. 
      </p>
      <p>
        Daniel is an avid sports fan with a love for participating and coaching team sports. He actively participated as an athlete at the high school and college levels and has been a successful coach at the middle school level.   
      </p>
      <p>
        Dan grew up in Marin County and now currently lives in Southern California. 
      </p>

      <hr />

      <p className="float-img float-none float-md-start me-md-3">
        <StaticImage 
          src="../images/aida-rahimi.png"
          alt="Aida Rahimi"
          placeholder="blurred"
          className="float-img text-center"
        />
      </p>
      <h2>Aida Rahimi <small>(she/her)</small></h2>
      <h3>M.S. CCC-SLP</h3>
      
      <p>
        Aida’s love and fascination with science led her to earning a B.S. in Cell & Molecular Biology at California State University Northridge (CSUN), where she continued on to receive her M.S. in Communication Sciences and Disorders in the area of Speech and Language Pathology in December 2012. 
      </p>
      <p>
        She has comprehensive experience in school based services at preschool, elementary and high school levels, in-home services for children under three, community-based services at a university clinic, and both in and out-patient services at a veterans hospital.
      </p>
      <p>
        While originally from Iran, She has grown up and lived in Germany, Florida, Southern California, and as of 2016, the Bay Area and Marin County. Her upbringing allows her to appreciate the rich diversity of our world, observe how our life experiences and shared experiences affect our relationships, and what it is like when a communication barrier complicates these connections. She believes that communication is at the center of who we are, working with and supporting each client and family as they develop the skills they need. 
      </p>

      <p>
        She currently lives in Southern California.
      </p>

      <hr />

      <p className="float-img float-none float-md-start me-md-3">
        <StaticImage 
          src="../images/Eliana-Rivera.jpg"
          alt="Eliana Rivera"
          placeholder="blurred"
          className="float-img text-center"
        />
      </p>
      <h2>Eliana Rivera <small>(she/her)</small></h2>
      <h3>M.S. CCC-SLP</h3>
      
      <p>
        Eliana received her B.A. in Communication Disorders from Augustana University in 2018, and her M.S. in Speech Language Pathology (with a medical emphasis) from Rocky Mountain University of Health Professions in 2020. She has experience in school and private practice settings and a passion for speech sound disorders, transgender voice therapy, and pediatric feeding therapy. She also has a background in American Sign Language and working with the Deaf community. She believes in treating the whole person, collaborating with other professionals and families, and the value diversity brings to individual experiences.
      </p>
      <p>
        Originally from Chicago, Illinois, Eliana enjoys living in Northern California and the Bay Area. In her free time she enjoys traveling, kayaking, baking, building relationships and connecting with her local community.
      </p>


      <hr />


      <p className="float-img float-none float-md-start me-md-3">
        <StaticImage 
          src="../images/kareem-bio.jpg"
          alt="Kareem Darwiche"
          placeholder="blurred"
          className="float-img text-center"
        />
      </p>
      <h2>Kareem Darwiche <small>(he/him)</small></h2>
      <h3>M.S. CCC-SLP</h3>
      
      <p>
        Kareem graduated with his Bachelor of Arts in Speech-Language-Hearing Sciences (magna cum laude) from the University of Minnesota – Twin Cities, and a Master of Science Degree in Communicative Disorders (magna cum laude) from San Francisco State University. He currently holds his ASHA Certification and CA State License in Speech-Language Pathology.
      </p>
      <p>
        Kareem has accrued years of experience in the school setting working with children and young adults of all ages, with extensive focus and expertise on Early Intervention/Pre-Kindergarten, Autism Spectrum Disorder, and individuals who are Deaf/Hard-of-Hearing. In addition to his title as a Speech-Language Therapist, Kareem works as a professional advocate for students/families: To help them navigate the educational system, facilitate access/connections to vital community services, and advocate for peer-to- peer inclusion opportunities for students with special needs. 
      </p>
      <p>
        Kareem was raised in SoCal (Ventura County) and happily called San Francisco his home up until recently, as he moved back down to Southern California. He has a passion for cooking/baking, learning languages (Spanish and ASL proficiency), traveling, and playing video games. 
      </p>
    
    </Container>
    


  </Layout>
)

export default IndexPage
